@import '~styles/global-imports';

.loginPage {
  min-height: 100vh;
}

.leftColumn {
  padding: $spacer-l;
  background: rgb(89 89 89);
  background: linear-gradient(90deg, rgb(89 89 89 / 100%) 0%, rgb(115 115 115 / 100%) 79%, rgb(101 101 101 / 100%) 100%);
}

.leftColumnContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.slogan {
  padding: $spacer-l;

  font-size: $h1-font-size;
  color: $clr-font-white;

  background: radial-gradient(circle farthest-corner at top left, $clr-font-white 0%, #727272 100%);
  background-clip: text;

  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.rightColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  width: 100%;
  max-width: 400px;
}
