@import '~styles/global-imports';

/* stylelint-disable-next-line color-function-notation */
$device-card-disabled-color: rgba(255, 255, 255, 50%);
$device-card-width: 152px;
$device-card-height: 128px;
$top-block-height: 40px;

.deviceCard {
  width: $device-card-width;
  min-height: $device-card-height;
  padding: $spacer-s;

  background-color: $color-bg-elevated;
  border: 0;
  /* stylelint-disable-next-line color-function-notation */
  border-bottom: 2px solid rgba(0, 0, 0, 10%);
  border-radius: $border-radius-xl;

  transition: backdrop-filter 0.3s;
}

.cardDisabled {
  background: $device-card-disabled-color;
  backdrop-filter: blur(10px);
}

.cardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: $top-block-height;
}

.iconsWrapper {
  position: relative;
}

.icon {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
}

.controlWrapper {
  font-size: $font-size-s;
  color: $color-text-tertiary;
}

.deviceCard:not(.cardDisabled) .iconActive {
  opacity: 1;
}

.cardDisabled .iconDisabled {
  opacity: 1;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.name,
.state {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color 0.3s;
}

.name {
  font-weight: $font-weight-bold;
}

.state {
  font-size: $font-size-s;
  color: $color-text-tertiary;
}

.cardDisabled .name {
  color: $color-text-secondary;
}

.cardDisabled .state {
  color: $color-text-quaternary;
}
