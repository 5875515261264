@import '~styles/global-imports';

.link {
  display: block;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
