@import '~styles/global-imports';

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  min-width: $page-min-width;
  height: 100%;
  margin: 0;

  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $color-text;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility;

  background-color: $color-bg-container;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

