.notFoundPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
}

.errorCode {
  font-size: 10em;
  line-height: initial;
}
