@import '~styles/global-imports';

$item-width: 275px;

.rulesItem {
  width: $item-width;
  padding: $spacer-s;
  white-space: nowrap;
}

.content {
  display: flex;
  flex-direction: row;
  gap: $spacer-s;
  justify-content: space-between;
}

.name {
  overflow: hidden;
  font-weight: $font-weight-bold;
  text-overflow: ellipsis;
}
