@use 'sass:color' as color;

$clr-palette-white-0: hsl(0deg 0% 0%);
$clr-palette-black-0: #000;
$clr-palette-white-100: color.adjust($clr-palette-white-0, $lightness: 100%);

// Base Colors
$color-primary: #1890FF;
$color-success: #52c41a;
$color-warning: #faad14;
$color-error: #ff4d4f;
$color-info: #1677ff;
$color-text: #000000e0;

// Brand colors
$color-primary-bg: #E6F7FF;
$color-primary-bg-hover: #BAE7FF;
$color-primary-border: #91D5FF;
$color-primary-border-hover: #69C0FF;
$color-primary-hover: #40A9FF;
$color-primary-active: #096DD9;
$color-primary-text-hover: #40A9FF;
$color-primary-text: #1890FF;
$color-primary-text-active: #096DD9;

// Success colors
$color-success-bg: #f6ffed;
$color-success-bg-hover: #d9f7be;
$color-success-border: #b7eb8f;
$color-success-border-hover: #95de64;
$color-success-hover: #95de64;
$color-success-active: #389e0d;
$color-success-text-hover: #73d13d;
$color-success-text: #52c41a;
$color-success-text-active: #389e0d;

// Warning colors
$color-warning-bg: #fffbe6;
$color-warning-bg-hover: #fff1b8;
$color-warning-border: #ffe58f;
$color-warning-border-hover: #ffd666;
$color-warning-hover: #ffd666;
$color-warning-active: #d48806;
$color-warning-text-hover: #ffc53d;
$color-warning-text: #faad14;
$color-warning-text-active: #d48806;

// Error colors
$color-error-bg: #fff2f0;
$color-error-bg-hover: #fff1f0;
$color-error-border: #ffccc7;
$color-error-border-hover: #ffa39e;
$color-error-hover: #ff7875;
$color-error-active: #d9363e;
$color-error-text-hover: #ff7875;
$color-error-text: #ff4d4f;
$color-error-text-active: #d9363e;

// Info colors
$color-info-bg: #e6f4ff;
$color-info-bg-hover: #bae0ff;
$color-info-border: #91caff;
$color-info-border-hover: #69b1ff;
$color-info-hover: #69b1ff;
$color-info-active: #0958d9;
$color-info-text-hover: #4096ff;
$color-info-text: #1677ff;
$color-info-text-active: #0958d9;

// Neutral colors
$color-text-secondary: #000000A6;
$color-text-tertiary: #00000073;
$color-text-quaternary: #00000040;
$color-text-light: #DDD; // not using in config
$color-border: #D9D9D9;
$color-border-secondary: #F0F0F0;
$color-fill: #00000026;
$color-fill-secondary: #0000000F;
$color-fill-tertiary: #0000000A;
$color-fill-quaternary: #00000005;
$color-bg-container: #fff;
$color-bg-elevated: #fff;
$color-bg-layout: #f5f5f5;
$color-bg-spotlight: #000000D9;
$color-bg-mask: #00000073;
