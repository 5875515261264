@import '~styles/global-imports';

.itemSelectedPrimary {
  & :global(.ant-menu-item.ant-menu-item-selected a span) {
    color: $color-primary;
  }

  & :global(.ant-menu-item.ant-menu-item-selected .ant-menu-item-icon path) {
    fill: $color-primary;
  }
}
