.pageWithHeaderLayout {
  min-height: 100vh;
}

.contentWrapper {
  height: 100vh;
}

.mainContent {
  overflow: auto;
  background-size: cover;
}
