/* stylelint-disable color-function-notation */
@import '~styles/global-imports';

$home-icon-size: 32px;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  // todo: ask team for padding variables
  padding: 10px 20px 10px 10px;

  line-height: initial;

  background-color: $color-fill-quaternary;
}

.heading {
  display: flex;
  gap: 16px;
  align-items: center;

  & h3 {
    margin: 0;
  }

  .icon {
    width: $home-icon-size;
    height: $home-icon-size;
    padding: 6px;

    background: $color-fill-secondary;
    border-radius: 50%;
  }
}
