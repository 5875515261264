@import '~styles/global-imports';

.dashboardPage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  min-height: 100%;
  padding: $spacer-s;
}
