@import '~styles/global-imports';

.sidebarHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  margin-bottom: $spacer-xs;
  padding: $spacer-xs $spacer-l;

  &.navCollapsed {
    justify-content: space-around;
  }
}

.navToggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
