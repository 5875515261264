/* stylelint-disable color-function-notation */
/* stylelint-disable scss/dollar-variable-empty-line-before */
@import '~styles/base/helpers';

// ****************************************
//   Variables
// ****************************************
// 1 - Color
// 2 - Font
// 3 - Grid and wrapper
// 4 - Spacing
// 5 - Borders
// 6 - Breakpoints
// 7 - Transitions
// 8 - Typography

// ---------------------------------------
// 1 - Color
// ---------------------------------------

$clr-font-white: #fff;

// ---------------------------------------
// 2 - Font
// ---------------------------------------

$font-family-base: 'Arial', sans-serif;
$font-weight-regular: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-regular;

$font-size-base-value: 14; // raw value for usage in pxToEm helper
$font-size-base: $font-size-base-value + px;

$line-height-l: 1.75;
$line-height-m: 1.5714;
$line-height-s: 1.25;

/* stylelint-disable-next-line function-name-case */
$font-size-s: pxToRem(12);

// $line-height-xs: 1;

$line-height-base: $line-height-m;

// ---------------------------------------
// 3 - Grid and wrapper
// ---------------------------------------

$page-min-width: 320px;

// $page-max-width: 1210px;
// $columns-gutter: 20px;
// $container-gutter: 20px;

// ---------------------------------------
// 4 - Spacing
// ---------------------------------------

$spacer-xss: 4px;
$spacer-xs: 8px;
$spacer-s: 12px;
$spacer-m: 16px;
$spacer-l: 20px;
$spacer-xl: 24px;

// ---------------------------------------
// 5 - Borders
// ---------------------------------------

$border-radius-lg: 8px;
$border-radius-xl: 12px;

// ---------------------------------------
// 6 - Breakpoints (include-media)
// ---------------------------------------

$breakpoints: (
  // 'screen-phone-s': 320px,
  'screen-phone': 480px,
  'screen-tablet': 768px,
  'screen-desktop': 1024px,
  // 'screen-widescreen': 1200px,
);

// ---------------------------------------
// 7 - Transitions
// ---------------------------------------

$animation-speed: 0.3s;
$transition: all $animation-speed ease-in-out;

// ---------------------------------------
// 8 - Typography
// ---------------------------------------

$h1-font-size: 38px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 16px;
$h6-font-size: 14px;

$h1-line-height: 1.2632;
$h2-line-height: 1.3333;
$h3-line-height: 1.3333;
$h4-line-height: 1.4;
$h5-line-height: 1.5714;
$h6-line-height: 1.5;
